<div class="bg-info py-5 text-white text-center">
  <h1>Registration</h1>
</div>

<div class="bg-light py-2">
  <div class="container">
    <ul class="nav nav-tabs registration-tabs">
      <li class="nav-item">
        <button class="nav-link" [class.active]="!scanMethod" (click)="scanMethod = false">Search</button>
      </li>
      <li class="nav-item">
        <button class="nav-link" [class.active]="scanMethod" aria-current="page" (click)="scanMethod = true">Scan App
        </button>
      </li>
    </ul>

    <div *ngIf="scanMethod" class="tab-content mx-3 my-4">
      <div class="row">
        <div class="col" *ngIf="!(scannerService.scanner$ | async)">
          <h5>Register Teams Using the App</h5>
          <p>Enter the code shown in the scanning companion app.</p>
          <div class="input-group my-4">
            <input #scanCode type="text" class="form-control" placeholder="Code (e.g. 1234)"
                   (keyup.enter)="scannerService.setScannerId(scanCode.value)">
            <button class="btn btn-primary" type="button" (keyup.enter)="scannerService.setScannerId(scanCode.value)">
              Connect
            </button>
          </div>
          <p *ngIf="scannerService.errorMessage" class="text-danger"><i
            class="fas fa-exclamation-circle me-2"></i>{{scannerService.errorMessage}}</p>
        </div>
        <div class="col" *ngIf="(scannerService.scanner$ | async) as scanner">
          <h5>Connected to Scanner {{scanner.id}}</h5>
          <p>This scanner has just scanned {{scanner.value}}</p>
          <button class="btn btn-primary" type="button" (click)="scannerService.disconnect()">Disconnect</button>
        </div>
        <div class="col" #spacer></div>
        <div class="col text-center px-4">
          <p class="lead">Widegame Scan Companion</p>
          <p class="scan-logo"><i class="fas fa-qrcode"></i></p>
          <p>Go to <a class="link-primary" href="https://scan.widegame.app" target="_blank">scan.widegame.app</a> on
            your phone to
            register teams using their cards.</p>
        </div>
      </div>
    </div>

    <div *ngIf="!scanMethod" class="tab-content mx-3 my-4">
      <div class="row">
        <div class="col">
          <h5>Search for teams to register</h5>
          <p>The list below only shows teams with the status 'Unregistered'.</p>
          <div class="input-group my-4">
            <input #search (input)="updateSearch($event.target.value)" pattern="[A-Za-z0-9]"
                   type="search" class="form-control" placeholder="Search teams..."
                   aria-label="Recipient's username">
          </div>
          <p *ngIf="scannerService.errorMessage" class="text-danger"><i
            class="fas fa-exclamation-circle me-2"></i>{{scannerService.errorMessage}}</p>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="container mt-5">
  <div *ngIf="searchResults$ | async as searchResults">

    <p>({{searchResults.length}})</p>

    <div class="mb-5 shadow" *ngFor="let runner of searchResults">
      <div class="p-2 bg-light border-0">
        <div class="card-body">
          <div class="row">

            <div class="col-md-4 border-end">
              <span class="h4" [innerHTML]="runner.name | highlightSearch: (searchTerm$ | async)"></span>
              <span class="h4 fw-normal"
                    [innerHTML]="' (' + (runner.teamNumber | number:'3.0' | highlightSearch: (searchTerm$ | async)) + ')'"></span>
              <h5 class="fw-normal mt-3">Group: <span class="fw-bold" [innerHTML]="runner.group | highlightSearch: (searchTerm$ | async)"></span></h5>
              <h5 class="fw-normal mt-3">Category: <span class="fw-bold">{{runner.category}}</span></h5>
              <h5 class="fw-normal mt-3">Missing info: <span class="fw-bold" [class.text-danger]="runnerService.checkRunner(runner).length > 0">{{runnerService.checkRunner(runner).length > 0 ? runnerService.checkRunner(runner).length : 'No'}} issues</span></h5>
            </div>

            <div class="col-md-4 border-end">
              <h5>Leader & Members</h5>
              <p class="mb-2">Team Leader: <b>{{runner.members[0]?.name ?? 'Not set!'}}</b></p>
              <p class="mb-2">Team Leader Phone: <b>{{runner.members[0]?.phone ?? 'Not set!'}}</b></p>
              <p class="mb-2">Team size: <b>{{runner.members.length}} members</b> (inc. leader)</p>
              <p class="mb-2">Other numbers: </p>
              <ul *ngIf="getOtherNumbers(runner).length > 0 else noOtherNumbers">
                <li *ngFor="let member of getOtherNumbers(runner)"><b>{{member.phone}}</b> ({{member.name}})</li>
              </ul>
              <ng-template #noOtherNumbers><ul><li>None</li></ul></ng-template>

            </div>

            <div class="col-md-4">
              <h5>Payment</h5>
              <div class="d-flex justify-content-between align-items-center mt-3">
                <div>Cost for this team:</div>
                <div class="h5 mb-0">{{game.currentGame.costPerPerson * runner.members.length | currency:'GBP'}}</div>
              </div>
              <div class="d-flex justify-content-between align-items-center mt-3">
                <div>Amount paid:</div>
                <div class="input-group w-50 ps-5">
                  <span class="input-group-text">£</span>
                  <input [ngModel]="runner.amountPaid"
                         (change)="runner.amountPaid = $event.target.value; runnerService.update(runner, 0, true)"
                         type="number" class="form-control fw-bold" aria-label="Amount">
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center mt-3">
                <div>Amount due:</div>
                <div class="h5 mb-0"
                     [class]="((game.currentGame.costPerPerson * runner.members.length) - (runner.amountPaid ?? 0)) == 0 ? 'text-success' : 'text-danger'">
                  {{((game.currentGame.costPerPerson * runner.members.length) - (runner.amountPaid ?? 0)) | currency:'GBP'}}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="bg-white border-0 text-end p-2">
        <button class="btn btn-light me-2" routerLink="/runners/{{runner.id}}">Edit</button>
        <button class="btn btn-info text-white" (click)="runnerService.register(runner.id)">Register</button>
      </div>

    </div>

      <h6 *ngIf="(searchResults$ | async)?.length < 1 && !runnerService.loading" class="text-center">All teams with this search criteria are registered</h6>

  </div>


  <app-loader *ngIf="runnerService.loading"></app-loader>
</div>

<!--<div *ngIf="runnerService.scannedTeam$">{{(runnerService.scannedTeam$ | async)?.id}}</div>-->

<!--<p *ngIf="runnerService.scannedTeam$ | async as team">{{team.name}} {{team.id}}</p>-->
