import {Injectable} from '@angular/core';
import {RunningTeam, RunningTeamStatus} from '../models/running-team';
import {Observable} from 'rxjs';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/compat/firestore';
import {GameService} from './game.service';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {DecimalPipe} from '@angular/common';
import {Game} from '../models/game';
import {ScannerService} from './scanner.service';
import firebase from 'firebase/compat/app';
import firestore = firebase.firestore;
import {ToastService} from "./toast.service";
import {AuthService} from "./auth.service";
import {BonusLife} from "../models/bonus-life";

@Injectable({
  providedIn: 'root'
})
export class RunnerService {

  teams$: Observable<RunningTeam[]>;
  currentTeam$: Observable<RunningTeam>;
  scannedTeam$: Observable<RunningTeam>;

  loading = false;

  timer = null;

  constructor(private afs: AngularFirestore, private gameService: GameService, private auth: AuthService,
              private decimalPipe: DecimalPipe, private scannerService: ScannerService, private toastService: ToastService) {
    // Get runner collection of current game and map collection.valueChanges to teams$
    this.loading = true;
    this.teams$ = this.getRunnerCollection().pipe(
      mergeMap(collection => collection.valueChanges()),
      tap(() => this.loading = false),
    );

    this.scannedTeam$ = this.scannerService.scanner$.pipe(mergeMap(scan => {
      console.log(scan);
      return this.getRunnerCollection().pipe(mergeMap(collection => {
        console.log('If error follows, due to scan:');
        return collection.doc(scan.value).valueChanges();
      }));
    }));
  }

  setRunner(id: string): void {
    this.loading = true;
    this.currentTeam$ = this.getRunnerCollection().pipe(
      mergeMap(collection => collection.doc(id).valueChanges()),
      tap(() => this.loading = false)
    );
  }



  update(runner: RunningTeam, wait: number = 1000, showDialog: boolean = false): void {
    this.timer = setTimeout(() => {
      this.getRunnerCollection().pipe(take(1)).subscribe(col => {
        console.log('Updating team!');
        col.doc(runner.id).update({...runner}).then(() => {
          if (showDialog) { this.toastService.show('Your running team has been successfully updated', 'Runner updated', 'success'); }
        })
          .catch(() => { this.toastService.show('Error updating runner!', null, 'danger'); });
      });
    }, wait);
    // batch.set(this.runnersCollection.doc(runner.id).ref, runner);
    // this.teams$ = this.gameService.currentGame$.pipe(mergeMap(game => {
    //   this.settingsDocument = this.afs.collection('games').doc<Game>(game.id);
    // })); // TODO: THIS HAS BEEN PASTED, BUT WILL NEED TO BE RUN FOR THE BELOW TO WORK!
    // this.settingsDocument.update({currentRunnerId: firebase.firestore.FieldValue.increment(1)}).then(res => console.log(res))
    //    .catch(err => console.log(err));
    // this.settingsDocument.valueChanges().subscribe(res => console.log(res));
    // batch.commit().then(res => console.log(res)).catch(err => console.log(err));
  }

  add(runner: RunningTeam): void {
      runner.teamNumber = this.gameService.currentGame.currentRunnerId + 1;
      runner.id = `R-${this.decimalPipe.transform(runner.teamNumber, '3.0')}`;
      runner.status = RunningTeamStatus.unregistered;
      runner.manager = this.auth.user.email;
      console.log(runner);
      this.getRunnerCollection().pipe(take(1)).subscribe(col => {
        col.doc(runner.id).set({...runner}).then(() => {
          this.afs.doc<Game>(`games/${this.gameService.currentGame.id}`).update({currentRunnerId: runner.teamNumber});
          console.log(runner.teamNumber);
          this.toastService.show('This team has been successfully created.', 'Team Created', 'success');
        }).catch(() => { this.toastService.show('There was an error creating this team. Please contact the organising team', 'Error!', 'danger'); });
    },
      () => { this.toastService.show('There was an error creating this team. Please contact the organising team', 'Error!', 'danger'); });

    // this.runnersCollection.doc<RunningTeam>(runner.id).set({...runner});
  }

  delete(id: string): void {
    this.getRunnerCollection().pipe(take(1)).subscribe(col => {
      col.doc(id).delete().then(() => {
        this.toastService.show('This team has been successfully deleted', 'Team deleted', 'success');
      }).catch(() => {
        this.toastService.show('There was an error deleting your team - please speak to the organisers.', 'Error!', 'danger');
      });
    });
  }

  addBonusLife(team: RunningTeam): void {
    if (!team.bonusLives) team.bonusLives = [];
    team.bonusLives.push({livesAdded: 1, type: 'Checkpoint', description: 'Team checked-in at the checkpoint'} as BonusLife);
    this.update(team, 0, true);
  }

  disqualify(id: string): void {
    this.getRunnerCollection().pipe(take(1)).subscribe(col => {
      col.doc(id).update({status: RunningTeamStatus.disqualified});
    });
  }

  checkIn(id: string): void {
    this.getRunnerCollection().pipe(take(1)).subscribe(col => {
      const date = Date.now();
      // @ts-ignore TODO: work out why this saves as firebase Timestamp and catching app doesn't
      col.doc(id).update({status: RunningTeamStatus.checkedIn, checkInTime: date, actualCheckInTime: date}).then(() => this.toastService.show('The team was successfully checked-in.', 'Team checked-in', 'success'));
    });
  }

  register(id: string): void {
    this.getRunnerCollection().pipe(take(1)).subscribe(col => {
      col.doc(id).update({status: RunningTeamStatus.registered}).then(() => this.toastService.show('The team was successfully registered.', 'Team registered', 'info'));
    });
  }

  unRegister(id: string): void {
    this.getRunnerCollection().pipe(take(1)).subscribe(col => {
      col.doc(id).update({status: RunningTeamStatus.unregistered});
    });
  }

  private getRunnerCollection(): Observable<AngularFirestoreCollection<RunningTeam>> {
    // Return observable of the collection for the current game
    return this.gameService.currentGame$.pipe(map(game => {
      return this.afs.collection<RunningTeam>(`games/${game.id}/runningTeams`);
    }));
  }

  checkRunner(team: RunningTeam): string[] {
    const issues: string[] = [];
    if (team.members?.length < 4) {
      issues.push('Running teams must have at least 4 members including the leader.');
    }
    if (team.members?.length > 7) { // Shouldn't happen anyway
      issues.push('Running teams cannot have more than 7 members including the leader.');
    }
    if (!team.contactName || !team.contactPhone) {
      issues.push('This team has incomplete home contact info. Provide at least name and phone.');
    }
    if (!team.name) {
      issues.push('This running team does not have a name yet!');
    }
    if (!team.group) {
      issues.push('You have not entered the scout group for this team.');
    }
    if (!team.category) {
      issues.push('You have not entered this team\'s category.');
    }
    let phoneNumbers = 0;
    team.members?.forEach((member, i) => {
      let name = 'member ' + (i + 1);
      if (!member.name) {
        issues.push('Team ' + name + ' must have a name!');
      }
      else { name = member.name; }
      if (!member.dob || !(member.dob instanceof firestore.Timestamp)) {
        issues.push('You must provide a date of birth for ' + name + '.');
      }
      if (member.phone?.length >= 10) {
        phoneNumbers += 1;
      }
    });
    if (phoneNumbers < 1 && team.members?.length > 0) {
      issues.push('Please provide phone numbers for at least 1 team member.');
    }

    return issues;
  }


}
