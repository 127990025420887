import {Component, OnInit} from '@angular/core';
import {RunnerService} from "../../../services/runner.service";
import {RunningTeam, RunningTeamStatus} from "../../../models/running-team";
import {AuthService} from "../../../services/auth.service";
import {RunningTeamMember} from "../../../models/running-team-member";
import {flatten} from "lodash";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [
  ]
})
export class DashboardComponent implements OnInit {

  constructor(public auth: AuthService, public runnerService: RunnerService) { }

  ngOnInit(): void {

  }

  getNumberOfRunners(runners: RunningTeam[]): number {
    return runners
      .filter(x => x.status === RunningTeamStatus.unregistered)
      .reduce((previousValue, currentValue) =>  previousValue + currentValue.members.length, 0)
  }

  getTeamDivisions(runners: RunningTeam[]): number[] {
    const splits = new Array<number>(4); // 4,5,6,7
    splits[0] = runners.filter(x => x.members.length == 4).length;
    splits[1] = runners.filter(x => x.members.length == 5).length;
    splits[2] = runners.filter(x => x.members.length == 6).length;
    splits[3] = runners.filter(x => x.members.length == 7).length;
    return splits
  }

  getNumberEating(runners: RunningTeam[]): number {
    return runners.reduce((previousValue, currentValue) =>  previousValue + currentValue.members.filter(x => x.wantsFood).length, 0)
  }

  getDietaryReqs(runners: RunningTeam[]): Array<RunningTeamMember> {
    return flatten(runners.map(r => r.members.filter(m =>
      !!m.dietaryReqs
      && !['none', 'non', 'na', 'n/a', 'no'].includes(m.dietaryReqs.toLowerCase())
    )));
  }

}
