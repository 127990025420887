import {Component} from '@angular/core';
import {GameService} from "../../../../services/game.service";
import {RunnerService} from "../../../../services/runner.service";
import {RunningTeam, RunningTeamStatus} from "../../../../models/running-team";

@Component({
  selector: 'app-print-runner-cards',
  templateUrl: './print-runner-cards.component.html',
  styleUrls: ['./print-runner-cards.component.scss']
})
export class PrintRunnerCardsComponent {
  constructor(public gameService: GameService, public runnerService: RunnerService) {

  }

  chunk(runners: RunningTeam[]) {
    runners = runners.filter(x => x.status === RunningTeamStatus.unregistered)
    const chunks = Array<Array<RunningTeam>>()
    for (let i = 0; i < runners.length; i += 3) {
      const theseRunners = runners.slice(i, i + 3);
      while (theseRunners.length < 3 && theseRunners.length > 0) { // Fill in empties
        theseRunners.push(new RunningTeam());
      }
      chunks.push(theseRunners);
      // do whatever
    }
    return chunks;
  }
}
